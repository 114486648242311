// COLORS
$primary-color: #5932e6;
$primary-light: #8632e6;
$secondary-color: #b332e6;
$secondary-light: #e032e6;

$white-color: #f8f8f8;

$dark-grey: #333333;
$light-grey: #d3d3d3;

$dark-text: #020314; // For Headings

// FONT SIZE
$default-font-size: 2rem;
$big-font-size: 4.6rem;
$mid-font-size: 2.5rem;
