@font-face {
  font-family: "Rigot";
  src: url("./fonts/Rigot-Regular.woff2") format("woff2"),
    url("./fonts/Rigot-Regular.woff") format("woff"),
    url("./fonts/Rigot-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-BoldItalic.woff2") format("woff2"),
    url("./fonts/Overlock-BoldItalic.woff") format("woff"),
    url("./fonts/Overlock-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-Italic.woff2") format("woff2"),
    url("./fonts/Overlock-Italic.woff") format("woff"),
    url("./fonts/Overlock-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-BlackItalic.woff2") format("woff2"),
    url("./fonts/Overlock-BlackItalic.woff") format("woff"),
    url("./fonts/Overlock-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-Black.woff2") format("woff2"),
    url("./fonts/Overlock-Black.woff") format("woff"),
    url("./fonts/Overlock-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-Regular.woff2") format("woff2"),
    url("./fonts/Overlock-Regular.woff") format("woff"),
    url("./fonts/Overlock-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overlock";
  src: url("./fonts/Overlock-Bold.woff2") format("woff2"),
    url("./fonts/Overlock-Bold.woff") format("woff"),
    url("./fonts/Overlock-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-family: "Rigot", sans-serif;
  font-weight: 900;
}

p,
a {
  font-weight: normal;
  font-size: $default-font-size;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  transition: all 0.3s ease-in-out;
}

.section-title {
  margin: 0px;
  margin-bottom: 4.5rem;
  font-size: $big-font-size;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Rigot", sans-serif;

  @include respond(phone) {
    font-size: 2.8rem;
  }
}

.dark-text {
  color: $dark-text !important;
}

.text-color-main {
  @include supportColorForIE11();

  // Responsive text purple style
  @include respond(phone) {
    background-image: none;
    -webkit-text-fill-color: $secondary-light;
  }

  &:hover {
    transform: translateX(2px);
  }
}
